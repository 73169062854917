/* 将所有元素默认的内外边距全部重置为零 */
* {
    margin: 0;
    padding: 0;
  }
  
  /* 设置页面的最小宽度和最小高度，并将页面高度撑满 */
  html {
    min-width: 100%;
    min-height: 100%;
    background-color: #ececec;
  }
  
  body {
    min-height: 100%;
    min-width: 1080px;
  }
  